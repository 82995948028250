import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';

import { useState, useEffect } from 'react';

import Topbar from './components/Topbar';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Footer from './components/Footer';

import navbar_buttons from './data/navbarButtons.json';
import coordinates from './data/coordinates.json';
import Contact from './components/Contact';
import Catalog from './components/Catalog';
const whatsapp_url = "https://wa.me/"+ coordinates.whatsapp_number.replace("+","").replace(/\s/g, '');

function App() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  useEffect(() => {
      fetch('https://api.diproalvi.com/index.php/categories/', {
          method: 'GET',
          headers: { 
              'Content-Type': 'application/json',
          }    
      }).then(response => {
          return response.json();
      }).then(data => {
          setCategories(data);
      });

      fetch('https://api.diproalvi.com/index.php/products/', {
          method: 'GET',  
          headers: {
              'Content-Type': 'application/json',
          }
      }).then(response => {
          return response.json();
      }
      ).then(data => {
          setProducts(data);
      });          
  }, []);

  return (
    <Router>
      <div className="App">
        <Topbar coordinates = {coordinates}/>
        <Navbar navbar_buttons = {navbar_buttons}/>
        <div className="content">
          <Switch>
            
            <Route exact path="/">
              <Home categories={categories}/>
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/catalog/*">
              <Catalog categories={categories} products={products}/>
            </Route>

            <Route exact path="/catalog">
              <Redirect to="/catalog/2" />
            </Route>

            <Route path="/contact">
              <Contact coordinates = {coordinates}/>
            </Route>

          </Switch>
        </div>
        <a href={whatsapp_url}  target="_blank" rel="noreferrer noopener" className="main-whatsapp-button"><img src="/icons/icons8-whatsapp 1.svg" alt="whatsapp icon"/></a>
        <Footer navbar_buttons = {navbar_buttons} coordinates = {coordinates}/>
      </div>
    </Router>
  );
}

export default App;
