import { Link } from 'react-router-dom';

import '../styles/Navbar.css';


const Navbar = (props) => {
    const navbar_buttons = props.navbar_buttons;

    const toggleMenu = () => {
        document.querySelector('.navbar-buttons').classList.toggle('active');  
        document.querySelector('.close-menu').classList.toggle('active');
        document.querySelector('.hamburger-menu').classList.toggle('active');     
        document.querySelector('.navbar').classList.toggle('to-col');
    }

    return (  
        <div className="navbar-wrapper">
            <nav className="navbar">
                <Link to="/"><img src="/logos/Logo fondo blanco (1).png" alt="Diproalvi logo transparente" className="logo" /> </Link>
                <button className="hamburger-menu active" onClick={toggleMenu}> 
                    <img src="/icons/icons8-hamburger-menu.svg" alt="Hamburger menu icon" />
                </button>
                <button className="close-menu" onClick={toggleMenu}>×</button>
                <div className="navbar-buttons">
                    {navbar_buttons.map((button) => (
                        <Link className="navbar-button" to={button.link} key={button.key}>
                            <p>{button.name}</p>
                        </Link>
                    ))}
                </div>
                <div className="ghost-div"></div>
            </nav>
            <p className="title-description">
                Distribuidora de Productos de&nbsp;Aluminio&nbsp;y&nbsp;Vidrio
            </p>
        </div>
    );
}
 
export default Navbar;