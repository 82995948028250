import '../styles/catalog.css'

import { BrowserRouter as Switch,Link,useRouteMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ProductModal from './ProductModal';

const Catalog = (props) => {
    const { url } = useRouteMatch();
    const categories_ids = url.split('/').slice(2);
    const parent_id = categories_ids[categories_ids.length - 1];
    const categories = props.categories;
    const products = props.products;
    const category_names = categories.map((category) => 
        categories_ids.map((id) =>
            category.id === parseInt(id) && category.name
        )
    ).flat().filter((name) => name !== undefined && name !== false);

    const [isOpen, setIsOpen] = useState(false);
    const [modal_data, setModalData] = useState();
    
    const [currentProducts, setCurrentProducts] = useState(false);
    useEffect(() => {

        //Scroll to top
        window.scrollTo(0, 0);

        if (url.split('/')[url.split('/').length - 1] === 'products') 
            setCurrentProducts(products.filter((product) => product.category_id == categories_ids[categories_ids.length -2]));
        else if (url.split('/')[url.split('/').length - 2] === 'products'){
            setCurrentProducts(products.filter((product) => product.category_id == categories_ids[categories_ids.length -3]));
            if (products.filter((product) => product.id == categories_ids[categories_ids.length -1])[0] !== undefined){
                setModalData(products.filter((product) => product.id == categories_ids[categories_ids.length -1])[0]);
                setIsOpen(true);
            }
        }
        else 
            setCurrentProducts(false);
        document.querySelector('.search-bar').value = '';
        document.querySelector('.catalog-container').style.display = 'flex';
        document.querySelector('.catalog-container-all').style.display = 'none';
    }, [url,products]);


    const searchHandler = (e) => {
        const value = e.target.value.toLowerCase();

        if (value !== '') {
            document.querySelector('.catalog-container').style.display = 'none';
            document.querySelector('.catalog-container-all').style.display = 'flex';
        }
        else{
            document.querySelector('.catalog-container').style.display = 'flex';
            document.querySelector('.catalog-container-all').style.display = 'none';
        }

        const cards = document.querySelectorAll(".catalog-product-card");
        cards.forEach((card) => {
            const name = card.querySelector("p").textContent.toLowerCase();
            const code =  card.querySelector("span").textContent.toLowerCase();
            if(name.includes(value) || code.includes(value)){
                card.style.display = "flex";
            }else{
                card.style.display = "none";
            }
        });

        if (e.code === 'Enter') {
            e.blur();
        }
    }


    const backHandler = () => {
        const current_url = url.split('/');
        
        const new_url = current_url.slice(
            0,
            current_url.length - (current_url[current_url.length - 1] === 'products'?2:1)
        ).join('/');

        window.location.href = new_url;

    }


    return (
        <div className="catalog">
            <div className="search-wrapper">
                <input className="search-bar" type="text" placeholder="Search.." onChange={searchHandler}/>
                <a className="download-button" download="CATALOGO DIPROALVI 2023" href="/CATALOGO DIPROALVI 2023.pdf">Descargar</a>
            </div>
            
            <div className="catalog-nav-menu">
                <button className="back-button" onClick={backHandler}>←</button>
                <div className="breadcrumbs">
                    <Link to="/catalog">{"CATEGORÍAS >"}</Link>
                    {category_names.map((name,index) => (
                        <p key={index}>{name + (index < category_names.length - 1 ? ' >' : '')}</p>
                    ))}
                </div>
            </div>

            <div className="catalog-container">
                {
                    ( currentProducts ?
                        currentProducts.map((product,index) => (
                        <Link to={`${url}/${product.id}`} className="catalog-product-card" key={product.id} id={product.id} onClick={() => { setIsOpen(true); setModalData(product); }}>
                            <img src={"https://api.diproalvi.com/uploads/"+product.main_image_path} alt="Product"/>
                            <p>{product.name}</p>
                            <span>{product.sku}</span>
                        </Link>
                    ))    
                    :
                    categories.map((category,index) => (
                        category.parent_id === parseInt(parent_id) &&
                            <Link to={`${url}/${category.id}`+(category.has_children ? '':'/products')} className="catalog-category-card" key={category.id} id={category.id}>
                                <img src={"https://api.diproalvi.com/uploads/"+category.image_path} alt="Category"/>
                                <p>{category.name}</p>
                            </Link> 
                    ))
                    )
                }
            </div>
            <div className="catalog-container-all">
                {
                    products.map((product) => (
                        <div className="catalog-product-card" key={product.id} id={product.id} onClick={() => { setIsOpen(true); setModalData(product); }}>
                            <img src={"https://api.diproalvi.com/uploads/"+product.main_image_path} alt="Product"/>
                            <p>{product.name}</p>
                            <span>{product.sku}</span>
                        </div>
                    ))
                }
            </div>

            {isOpen && <ProductModal setIsOpen={setIsOpen} modal_data={modal_data}/>}

        </div>
    );
}
 
export default Catalog;