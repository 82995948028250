import '../styles/topbar.css'

const Topbar = (props) => {
    const coordinates = props.coordinates;

    const whatsapp_url = "https://wa.me/"+ coordinates.whatsapp_number.replace("+","").replace(/\s/g, '');
    const phone_url = "tel:" + coordinates.main_fixed_phone_number;

    return ( 
        
        <nav className="topbar">
             <a className="topbar-button-wrapper" target="_blank" rel="noopener noreferrer" href={whatsapp_url}>
                <img src="/icons/icons8-whatsapp 1.svg" alt="whatsapp icon"/>
                <p>{coordinates.whatsapp_number}</p>
            </a>
            
            <a className="topbar-button-wrapper" target="_blank" rel="noopener noreferrer" href={phone_url}>
                <img src="/icons/phone 1.svg" alt="phone icon"/>
                <p>{coordinates.main_fixed_phone_number}</p>
            </a>

            <a className="topbar-button-wrapper hide-on-mobile" target="_blank" rel="noopener noreferrer" href={coordinates.google_maps_link}>
                <img src="/icons/location 1.svg" alt="phone icon"/>
                <p>{coordinates.physical_address}</p>
            </a>

            <a className="topbar-button-wrapper" target="_blank" rel="noopener noreferrer" href={coordinates.facebook_link}>
                <img src="/icons/icons8-facebook 1.svg" alt="phone icon"/>
            </a>

            {/*<div className="topbar-button-wrapper">
                <select name="language" id="language_selector" disabled>
                    <option value="esp">ESP</option>
                </select>
            </div> */}

        </nav>
    );
}
 
export default Topbar;