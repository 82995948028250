import CatalogPreview from './CatalogPreview';
import MeetTheTeam from './MeetTheTeam';

import '../styles/home.css';

import team from '../data/team.json';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Home = (props) => {

    const categories = props.categories;

    return ( 
        <div className="home">
            <div className="hero">
                <div className="headers">
                    <h1>El mejor socio para <br/> tus proyectos de instalación</h1>
                </div>

                <CatalogPreview categories = {categories}/>

                <Link className="action-button" to="/catalog/2">Ver Catálogo</Link>
            </div>
            
            <MeetTheTeam team = {team}/>

            <div className="home-aside-wrapper about-home">
                <div className="aside-text-wrapper">
                    <div className="aside-text">
                        <h4>Nosotros</h4>
                        <p>Llevamos más de 30 años surtiendo a los instaladores locales con los mejores herrajes y accesorios para sus proyectos. Diproalvi es reconocido por su atención especializada y por la alta calidad de sus productos y servicios.</p>
                    </div>
                </div>
            </div>

            <div className="home-aside-wrapper vision-home">
                <div className="aside-text-wrapper">
                    <div className="aside-text">
                        <h4>Visión</h4>
                        <p>Convertirnos en el aliado más confiable de los instaladores nacionales y ser una empresa que genere valor a sus clientes, proveedores y colaboradores a través de un trato ético y amable.</p>
                    </div>
                </div>
            </div>

            <div className="home-aside-wrapper mision-home">
                <div className="aside-text-wrapper">
                    <div className="aside-text">
                        <h4>Misión</h4>
                        <p>Ofrecer los mejores herrajes y accesorios para la industria de aluminio y vidrio y brindar soluciones integrales que garanticen la calidad de los proyectos terminados de nuestros clientes.</p>
                    </div>
                </div>
            </div>

            <div className="success">
                <h3>Somos distribuidores de éxito</h3>
                <div>
                    <img src="/logos/logo olimpia 1.png" alt="Logo Olimpia"/>
                    <img src="/logos/CRL logo 1.png" alt="Logo CRL"/>
                    <img src="/logos/Logo-Phifer-AltColorLogo-V1.0.png" alt="Pfifer"/>
                    <p><span>500+</span><br/>productos</p>
                </div>
            </div>

        </div>
     );
}
 
export default Home;