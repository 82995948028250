import "../styles/about.css"

const About = () => {
    return ( 
        <div className="about">
            <section className="history">
                <div className="headers">
                    <h2>Nuestra Historia</h2>
                    <h3 className="subheading">Desde 1992</h3>
                </div>
                <div className="content">
                    <p>Diproalvi fue fundado en 1992, por el Ing. Antonio Cladellas, quien cuenta con una amplia experiencia en el mundo de aluminio y vidrio. 
                        <br/>
                        Hace 31 años, nace el proyecto Diproalvi con el objetivo de cubrir la necesidad y saciar la alta demanda de herrajes y accesorios que complementan los proyectos de instalación y construcción. Desde sus inicios, Diproalvi ha sido una empresa con una trayectoria firme y estable. Una compañía reconocida por la alta calidad de sus productos, por sus alianzas con proveedores de prestigio mundial y por su cercanía y trato amable para con sus clientes, asociados y colaboradores. 
                        <br/>
                        Actualmente, la empresa se especializa en surtir una amplia gama de herrajes y accesorios que garantizan la calidad y duración de los proyectos terminados de sus clientes.</p>
                    <img src="backgrounds/team-pic.jpeg" alt="Equipo" />
                </div>
            </section>

            <section className="mission-vission">
                <div className="mission">
                    <div className="mission-text">
                        <h2>Misión</h2>
                        <p>Ofrecer los mejores herrajes y accesorios para la industria de aluminio y vidrio y brindar soluciones integrales que garanticen la calidad de los proyectos terminados de nuestros clientes.</p>
                    </div>
                </div>
                <div className="vission">
                    <div className="vission-text">
                        <h2>Visión</h2>
                        <p>Convertirnos en el aliado más confiable de los instaladores nacionales y ser una empresa que genere valor a sus clientes, proveedores y colaboradores a través de un trato ético y amable.</p>
                    </div>
                </div>
            </section>

             <section className="values">
                <h2>Nuestros Valores</h2>
                <div className="values-row">
                    <div className="value-card">
                        <img src="/icons/integridad.svg" alt="Integridad" />
                        <h3>Integridad</h3>
                        <p>Somos honestos, respetuosos y éticos, aunque nadie nos esté mirando.</p>
                    </div>
                    <div className="value-card">
                        <img src="/icons/cercania.svg" alt="Cercanía" />
                        <h3>Cercanía</h3>
                        <p>Tratamos a todos nuestros clientes, proveedores y colaboradores con atención, amabilidad y de forma personal.</p>
                    </div>
                    <div className="value-card">
                        <img src="/icons/calidad.svg" alt="Calidad" />
                        <h3>Calidad</h3>
                        <p>Ofrecemos productos y soluciones que sí funcionan y superan las expectativas de nuestros clientes, todo a un precio accesible.</p>
                    </div>
                    <div className="value-card">
                        <img src="/icons/rapidez.svg" alt="Rapidez" />
                        <h3>Rapidez</h3>
                        <p>Somos capaces de llevar a término nuestros objetivos y de cumplirle a nuestros clientes y proveedores de forma rápida.</p>
                    </div>
                </div>
             </section>

        </div>
     );
}
 
export default About;