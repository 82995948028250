import '../styles/productModal.css';
import { BrowserRouter as Switch,Link,useRouteMatch } from 'react-router-dom';
import { useState, useEffect } from 'react';

const ProductModal = (props) => {
    const setIsOpen = props.setIsOpen;
    const modal_data = props.modal_data;

    const [product_secondary_images, setProductSecondaryImages] = useState(null);
    const [supplier, setSupplier] = useState(null);
    const [color_variants, setColorVariants] = useState(null);
    
    const [current_variant_data, setCurrentVariantData] = useState(color_variants?color_variants[0]:null);
    
    useEffect(() => {
        fetch('https://api.diproalvi.com/index.php/product-secondary-images/?product_id='+modal_data.id, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }    
        }).then(response => {
            return response.json();
        }).then(data => {
            setProductSecondaryImages(data);
        });
        fetch('https://api.diproalvi.com/index.php/suppliers/?id='+modal_data.supplier_id, {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
            }    
        }).then(response => {
            return response.json();
        }).then(data => {
            setSupplier(data[0]);
        });
        fetch('https://api.diproalvi.com/index.php/color-variants/?product_id='+modal_data.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            return response.json();
        }
        ).then(data => {
            setColorVariants(data);
            setCurrentVariantData(data[0]);
        });
    }, [modal_data]);

    const handleColorVariantClick = (color_variant) => {
        setCurrentVariantData(color_variant)
    }

    const { url } = useRouteMatch();

    const scrollLeft = () => {
        const caroussel = document.querySelector('.product-img-caroussel-items');
        if (window.innerWidth > 768)
            caroussel.scrollLeft -= 320;
        else
            caroussel.scrollLeft -= 200;
    }

    const scrollRight = () => {
        const caroussel = document.querySelector('.product-img-caroussel-items');
        if (window.innerWidth > 768)
            caroussel.scrollLeft += 320;
        else
            caroussel.scrollLeft += 200;
    }
    return (
            <div className="product-modal modal">
                <div className="modal-content" >
                    <Link to={url.split('/').slice(0,-1).join('/')} className="close-modal-button"  onClick={() => setIsOpen(false)}>×</Link>

                    <div className="product-img-caroussel" >
                        <div className="arrow prev" onClick={scrollLeft}>‹</div>
                        <div className="product-img-caroussel-items">
                            <img src={current_variant_data?"https://api.diproalvi.com/uploads/"+current_variant_data.main_image_path:"https://api.diproalvi.com/uploads/"+modal_data.main_image_path} alt="Producto"/>
                            {product_secondary_images && product_secondary_images.map((image) => (
                                <img src={"https://api.diproalvi.com/uploads/"+image.image_path} key={image.id} alt="Producto"/>
                            ))}
                        </div>
                        <div className="arrow next" onClick={scrollRight}>›</div>
                    </div>    
                    <div className="product-headers">           
                        <h3 className="poduct-name">{modal_data.name}</h3>
                        <span className="product-code">{current_variant_data?current_variant_data.sku:modal_data.sku}</span>
                    </div>
                    <div className="product-description">
                        <p style={{whiteSpace: 'pre-line'}}>{modal_data.description}</p>
                    </div>
                    {
                        supplier &&
                        <div className="supplier-logo-wrapper">
                            {supplier.logo_path ? <img src={"https://api.diproalvi.com/uploads/"+supplier.logo_path} alt={supplier.name} /> : "" }
                        </div>
                    }
                    {
                        color_variants &&
                        <div className="color-variants">
                            {
                            color_variants.map((color_variant,index) => (
                                <button className={"color-variant" + (color_variant.id === current_variant_data.id?" active":"")} key={color_variant.id} style={{backgroundColor: color_variant.hex}} onClick={
                                    () => handleColorVariantClick(color_variant)
                                }></button>
                            ))}
                        </div>
                    }
                </div>
            </div>
        );
}

export default ProductModal;
