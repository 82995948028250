import '../styles/footer.css'

const Footer = (props) => {
    const navbar_buttons = props.navbar_buttons;
    const coordinates = props.coordinates;

    const whatsapp_url = "https://wa.me/"+ coordinates.whatsapp_number.replace("+","").replace(/\s/g, '');
    const phone_url = "tel:" + coordinates.main_fixed_phone_number;
    const mail_url = "mailto:" + coordinates.contact_email_address;

    return ( 
        <footer>
            <a href="/"><img src="/logos/Logo monocromatico claro.png" alt="Logo Diproalvi Monocromatico claro"/></a>
            <div className="footer-card-wrapper">
                <div className="footer-card">
                    <h4>Conócenos</h4>
                    <ul>
                        {navbar_buttons.map((button) => (
                            <li key={button.key}>
                                <a href={button.link}>{button.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="footer-card">
                    <h4>Contáctanos</h4>
                    <ul>
                        <li><a href={whatsapp_url} target="_blank" rel="noreferrer noopener">Whatsapp: {coordinates.whatsapp_number}</a></li>
                        <li><a href={phone_url} target="_blank" rel="noreferrer noopener">Teléfono: {coordinates.main_fixed_phone_number}</a></li>
                        <li><a href={mail_url} target="_blank" rel="noreferrer noopener">{coordinates.contact_email_address}</a></li>
                        <li><a href={coordinates.google_maps_link} target="_blank" rel="noreferrer noopener">{coordinates.physical_address}</a></li>
                        <div className="social-media">
                            <a href={coordinates.facebook_link} target="_blank" rel="noreferrer noopener"><img src="/icons/icons8-facebook 1.svg" alt="Facebook icon"/></a>
                            <a href={whatsapp_url} target="_blank" rel="noreferrer noopener"><img src="/icons/icons8-whatsapp 1.svg" alt="Whatsapp icon"/></a>
                            <a href={mail_url} target="_blank" rel="noreferrer noopener"><img src="/icons/email-svgrepo-com 1.svg" alt="Email icon"/></a>
                            <a href={coordinates.waze_url} target="_blank" rel="noreferrer noopener" className="to-white"><img src="/icons/waze 1.svg" alt="Waze icon"/></a>
                        </div>
                    </ul>
                </div>

                <div className="footer-card">
                    <h4>Legal</h4>
                    <ul>
                        <li><a href="/legal">Información Legal</a></li>
                        <li><a href="/privacy">Respeto de la privacidad</a></li>
                    </ul>
                </div>
            </div>
        </footer>
        );
}

export default Footer;