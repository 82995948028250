import '../styles/contact.css'


const Contact = (props) => {
    const coordinates = props.coordinates;
    const phone_numbers = coordinates.main_fixed_phone_number + " / " + coordinates.secondary_fixed_phone_number;

    const whatsapp_url = "https://wa.me/"+ coordinates.whatsapp_number.replace("+","").replace(/\s/g, '');

    return ( 
        <div className="contact">
            <div className="contact-row">
                <div className="contact-card">
                    <a href={whatsapp_url} target="_blank" rel="noreferrer noopener"  className="contact-card-row">
                        <img src="/icons/whatsapp-black.svg" alt="Whatsapp Icon" />
                        <p>Contáctanos por Whatsapp <br/> <span> { coordinates.whatsapp_number } </span></p>
                    </a>
                    <a href={"mailto:"+coordinates.contact_email_address} target="_blank" rel="noreferrer noopener"  className="contact-card-row">
                        <img src="/icons/email-black.svg" alt="Email Icon" />
                        <p>Contáctanos por email <br/> <span> { coordinates.contact_email_address } </span></p>
                    </a>
                    <a href={"tel:"+coordinates.main_fixed_phone_number} target="_blank" rel="noreferrer noopener"  className="contact-card-row">
                        <img src="/icons/phone-black.svg" alt="Phone Icon" />
                        <p>Llámanos <br/> <span> { phone_numbers } </span></p>
                    </a>
                </div>
                
                <div className="contact-card">
                    <p>Visítanos!</p>
                    <a href="https://goo.gl/maps/1eYJPGLbdPdDABqVA" target="_blank" rel="noreferrer noopener">
                        <img id="map" src="mapsDipro.png" alt="Map" />
                    </a>
                    <p>Estamos ubicados en <br/> <span>{coordinates.physical_address}</span></p>
                    <a href={coordinates.waze_url} target="_blank" rel="noreferrer noopener"  className="contact-card-row">
                        <img src="/icons/waze 1.svg" alt="Waze Icon" />
                        <p> {coordinates.waze_name}</p>
                    </a>
                </div>
            </div>
            
        </div>
     );
}
 
export default Contact;