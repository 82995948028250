import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CatalogPreview = (props) => {

    const categories = props.categories.filter((category) => category.parent_id === 2);
    const categories_in_page = Math.floor((window.innerWidth-180)*0.8 / 200);
    const number_of_pages = Math.ceil(categories.length / categories_in_page);
    
    const [activeIndex, setActiveIndex] = useState(0);
    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % number_of_pages);
    };
    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + number_of_pages) % number_of_pages);
    };

    const currentTransform = -activeIndex * ((window.innerWidth-180) *0.8); 


    const handleKeyDown = (event) => {
        if (event.key === "ArrowRight") {
            handleNext();
        } else if (event.key === "ArrowLeft") {
            handlePrev();
        }
    };

    return ( 
        <div className="catalog-preview" onKeyDown={handleKeyDown} tabIndex="0">
            {<div className="arrow prev" onClick={handlePrev}>‹</div>}
            <div className="catalog-items" >
                {categories && categories.map((category) => (
                    <Link to={"/catalog/2/"+category.id + (category.has_children === 1? '':'/products')} className="catalog-card" key={category.id} style={{ transform: `translateX(${currentTransform}px)` }}>
                        <img src={"https://api.diproalvi.com/uploads/"+category.image_path} alt="catalog category"/>
                        <p>{category.name}</p>
                    </Link>
                ))}
            </div>

            {<div className="arrow next" onClick={handleNext}>›</div>}
        </div>
     );
}
 
export default CatalogPreview;