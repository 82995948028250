const MeetTheTeam = (props) => {
    const team = props.team;

    return ( 
        <div className="meet-the-team">
            {team.map((member) => (
                <div className="team-card" key={member.key}>
                    <img src={member.photo} alt="team member"/>
                    <p>{member.name}</p>
                    <span>{member.position}</span>
                </div>
            ))}
        </div>
     );
}
 
export default MeetTheTeam;